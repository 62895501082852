import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    companyDialogContainer: {
        margin: 'auto'
        // padding: theme.spacing(3)
    },
    dialogGridContent: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '90%',
        minWidth: '600px',
        minHeight: '195px'
    },
    companyDialogLoadingContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '90%',
        minWidth: '600px',
        minHeight: '240px'
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%'
    },
    select: {
        height: theme.spacing(5)
    },
    userPopoverDeleteButton: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    deleteUserDialogCancelButton: {
        color: theme.palette.secondary.dark
    },
    RequestPasswordRecoveryDialogCancelButton: {
        color: theme.palette.secondary.dark
    },
    titleText: {
        fontSize: 30,
        color: theme.palette.secondary.contrastText,
        fontWeight: 600,
        textAlign: 'center'
    },
    betaChip: {
        marginLeft: '6px',
        height: '17px',
        paddingTop: '2.5px',
        fontWeight: 'bold',
        color: '#fff',
        backgroundColor: '#e87002'
    },
    successMessage: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
        fontSize: 17,
        fontWeight: 300,
        textAlign: 'center'
    },
    closeButton: {
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    },
    inputField: {
        width: '100%',
        marginBottom: theme.spacing(3),
        '& .MuiInputBase-root': {
            color: 'inherit'
        }
    },
    imageLoading: {
        margin: 'auto',
        minWidth: '100px',
        minHeight: '100px'
    },
}))

export default useStyles
