import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Divider from '@/components/Divider'
import frontendConfig from '@/config/frontend'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import { CodecRoleType, CompanyType, ProductType, UserType } from '@/pages/Intranet/Companies'
import ApiService from '@/services/Api'
import {
    EditOutlined as EditIcon,
    DeleteOutline as DeleteIcon,
    LaunchOutlined as AccessUserAccountIcon,
    VpnKeyOutlined as VpnKeyIcon,
    CheckCircleOutline as CheckCircleIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Check as CheckIcon,
    FileCopy as FileCopyIcon
} from '@mui/icons-material'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Dialog,
    DialogTitle,
    TextField,
    IconButton,
    DialogActions,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Checkbox,
    FormControlLabel,
    Chip,
    SvgIcon,
    InputAdornment
} from '@mui/material'

import useStyles from './styles'

interface IUser extends Partial<UserType> {
    password?: string
}

const Users: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const params: any = useParams()
    const { companyUuid } = params

    const [company, setCompany] = useState<CompanyType>()
    const [user, setUser] = useState<IUser>({
        product: 'codec',
        role: 'communicator',
        isBetaTester: false
    })
    const [betaTester, setBetaTester] = useState<boolean | undefined>(false)
    const [newPassword, setNewPassword] = useState<string | null>(null)
    const [recoveryPasswordDisabledEmails] = useState<Array<string>>([])

    const [copied, setCopied] = useState<boolean>(false)
    const [hidden, setHidden] = useState<boolean>(true)

    const [loadingUser, setLoadingUser] = useState<boolean>(false)
    const [openedUserDialog, setOpenedUserDialog] = useState<boolean>(false)

    const [openedDeleteUserDialog, setOpenedDeleteUserDialog] = useState<boolean>(false)
    const [openedRequestPasswordRecoveryDialog, setOpenedRequestPasswordRecoveryDialog] = useState<boolean>(false)
    const [loadingDeleteUser, setLoadingDeleteUser] = useState<boolean>(false)
    const [loadingRequestPasswordRecovery, setLoadingRequestPasswordRecovery] = useState<boolean>(false)

    const openUserDialog = (user?: UserType) => {
        setNewPassword(null)
        setBetaTester(user?.isBetaTester)
        setUser(user || {
            product: 'codec',
            role: 'communicator'
        })
        setOpenedUserDialog(true)
    }

    const handleCloseUserDialog = () => {
        setOpenedUserDialog(false)
    }

    const getCompany = async () => {
        try {
            const { data } = await ApiService.get(`/company/${companyUuid}`) // CompanyType

            setCompany(data)
        } catch (error: any) {
            enqueueSnackbar(error?.data || 'Ocorreu um ao erro desconhecido, recarregue a página.', {
                variant: 'error'
            })
        }
    }

    const openDeleteUserDialog = async (user: IUser) => {
        setUser(user)
        setOpenedDeleteUserDialog(true)
    }

    const handleDeleteUser = async () => {
        setLoadingDeleteUser(true)

        try {
            await ApiService.delete(`/company/${companyUuid}/user/${user.uuid}`)

            setOpenedDeleteUserDialog(false)
            setUser({
                product: 'codec',
                role: 'communicator',
                isBetaTester: false
            })

            enqueueSnackbar('Usuário removido com sucesso.', {
                variant: 'success'
            })
        } catch (error: any) {
            enqueueSnackbar(error?.data || 'Ocorreu um erro ao remover o usuário.', {
                variant: 'error'
            })
        }

        setLoadingDeleteUser(false)

        getCompany()
    }

    const handleRequestPasswordRecovery = async () => {
        setLoadingRequestPasswordRecovery(true)

        try {
            if (recoveryPasswordDisabledEmails.includes(user?.email || '')) {
                throw new Error('Aguarde 30 segundos para solicitar a recuperação de senha novamente.')
            }

            await ApiService.get(
                `metrics|recovery-password/email/${user?.email}`
            )

            recoveryPasswordDisabledEmails.push(user?.email || '')

            enqueueSnackbar(
                'O link para recuperação da senha foi enviado com sucesso para o email do usuário.',
                { variant: 'success' }
            )

            await setTimeout(
                () => recoveryPasswordDisabledEmails
                    .splice(recoveryPasswordDisabledEmails.indexOf(user?.email || ''), 1)
                , 30000
            )
        } catch (error: any) {
            enqueueSnackbar(
                error?.message || error?.data || 'Ocorreu um erro ao requisitar a troca de senha.',
                { variant: 'error' }
            )
        }

        setLoadingRequestPasswordRecovery(false)

        setOpenedRequestPasswordRecoveryDialog(false)
    }

    const saveUser = async () => {
        try {
            setLoadingUser(true)

            const { data } = await ApiService.post(`/company/${companyUuid}/user`, user)

            setNewPassword(data.password)
        } catch (error: any) {
            enqueueSnackbar(error?.data || 'Ocorreu um erro ao salvar o usuário.', {
                variant: 'error'
            })
        }

        getCompany()

        setLoadingUser(false)
    }

    const handleAccessUserAccount = async (user: UserType) => {
        try {
            const { data: token } = await ApiService.post(`/company/${user.companyUuid}/user/${user.uuid}/token`)

            const url = `http://${user.product}.${frontendConfig.frontendBaseUrl}/?token=${token}`

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            window.open(url, '_blank')?.focus()
        } catch (error: any) {
            enqueueSnackbar(error?.data || 'Ocorreu um erro ao tentar acessar a conta do usuario.', {
                variant: 'error'
            })
        }
    }

    const formatRole = {
        studio: 'Estúdio',
        communicator: 'Repórter',
        admin: 'Administrador'
    }

    const selectProduct = ({ target }: {
        target: {
            name?: string | undefined;
            value: unknown;
        }
    }) => {
        if (target.value === 'metrics') {
            return setUser({ ...user, isBetaTester: betaTester, product: target.value as ProductType })
        }
        setUser({ ...user, isBetaTester: false, product: target.value as ProductType })
    }

    const checkBetaTester = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, isBetaTester: target.checked })
        setBetaTester(target.checked)
    }

    const handleCopy = (text: string | number | undefined) => {
        setCopied(true)
        navigator.clipboard.writeText(String(text))

        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    useFetch<CompanyType>(
        `/company/${companyUuid}`,
        (data: CompanyType) => {
            setCompany(data)
        }
    )

    useDidMount(() => {
        getCompany()
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography className={classes.titleText}>
                        USUÁRIOS
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={() => openUserDialog()}>
                    Criar novo usuário
                </Button>
            </Grid>

            <Divider size={3} />
            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Produto</TableCell>
                                <TableCell align="right">Função</TableCell>
                                <TableCell align="right" />
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                company?.users?.map((user) => (
                                    <TableRow key={user.uuid}>
                                        <TableCell component="th" scope="row">
                                            {user.name}
                                        </TableCell>
                                        <TableCell align="right">{user.email}</TableCell>
                                        <TableCell align="right">{user.product}</TableCell>
                                        <TableCell align="right">{formatRole[user.role]}</TableCell>
                                        <TableCell align="right">
                                            {user.isBetaTester && (
                                                <Chip className={classes.betaChip} size='medium' label="BETA" />
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => {
                                                    setUser(user)
                                                    setOpenedRequestPasswordRecoveryDialog(true)
                                                }}
                                            >
                                                <VpnKeyIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleAccessUserAccount(user)}
                                            >
                                                <AccessUserAccountIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => openUserDialog(user)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => openDeleteUserDialog(user)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openedUserDialog}>
                <DialogTitle className={classes.dialogTitle}>
                    { !newPassword
                        ? !user?.uuid
                            ? 'Criando novo usuário'
                            : 'Editando o usuário'
                        : 'Sucesso'
                    }
                </DialogTitle>
                {
                    loadingUser && !user.uuid &&
                    <>
                        <Grid className={classes.dialogGridContent}>
                            <Grid container className={classes.companyDialogLoadingContainer} justifyContent="center">
                                <CircularProgress size={80} color="primary" className={classes.imageLoading} />
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    (!loadingUser || user.uuid) && !newPassword &&
                    <>
                        <Grid className={classes.dialogGridContent}>
                            <Grid container className={classes.companyDialogContainer}>
                                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                                    <TextField
                                        className={classes.itemInput}
                                        label="Nome" size="small" color="primary" variant="outlined"
                                        value={user.name}
                                        onChange={({ target }) => setUser({ ...user, name: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                                    <TextField
                                        className={classes.itemInput}
                                        label="Email" size="small" color="primary" variant="outlined"
                                        value={user.email}
                                        onChange={({ target }) => setUser({ ...user, email: target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Produto</InputLabel>
                                        <Select
                                            value={user.product}
                                            onChange={selectProduct}
                                            variant="outlined"
                                            color="primary"
                                            label="Produto"
                                            className={classes.select}
                                        >
                                            <MenuItem value="metrics">Metrics</MenuItem>
                                            <MenuItem value="club">Clube de vantagens</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {user.product === 'metrics' && (
                                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                                        <FormControlLabel
                                            className={classes.itemInput}
                                            control={
                                                <Checkbox checked={user.isBetaTester} onChange={checkBetaTester} color="default" />
                                            }
                                            label={'Acesso a recursos em beta'}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Divider size={3} />

                        <DialogActions>
                            <Button onClick={handleCloseUserDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button
                                onClick={saveUser} color="primary" autoFocus variant="contained"
                                endIcon={loadingUser && (
                                    <CircularProgress size={20} color="inherit" />
                                )}
                            >
                                {user.uuid ? 'Salvar' : 'Criar'}
                            </Button>
                        </DialogActions>
                    </>
                }
                {
                    !loadingUser && newPassword &&
                    <>
                        <Grid className={classes.dialogGridContent}>
                            <Grid container className={classes.companyDialogContainer} alignContent="center" justifyContent="center">
                                <Typography className={classes.successMessage}>
                                Atenção! Você está visualizando uma senha gerada aleatoriamente
                                que não poderá ser exibida novamente. Para alterar a senha,
                                é preciso solicitar a redefinição.
                                </Typography>

                                <Grid item>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <TextField
                                            className={classes.inputField}
                                            variant="outlined"
                                            label='Senha'
                                            type={hidden ? 'password' : 'text'}
                                            size="small"
                                            focused={false}
                                            autoFocus={true}
                                            disabled
                                            InputLabelProps={{
                                                shrink: !(!newPassword || newPassword === '')
                                            }}
                                            value={newPassword}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="Copia senha para área de trabalho"
                                                            onClick={() => handleCopy(newPassword)}
                                                            edge="end"
                                                        >
                                                            {copied ? <CheckIcon /> : <FileCopyIcon />}
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="Torna senha visivel"
                                                            onClick={() => setHidden(!hidden)}
                                                            edge="end"
                                                        >
                                                            {!hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Divider size={1} />

                                <Button
                                    color="primary"
                                    autoFocus
                                    variant="contained"
                                    className={classes.closeButton}
                                    endIcon={loadingUser && (
                                        <CircularProgress size={20} color="inherit" />
                                    )}
                                    onClick={() => {
                                        handleCloseUserDialog()
                                    }}
                                >
                                    Fechar
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Dialog>

            <Dialog aria-labelledby="simple-dialog-title" open={openedDeleteUserDialog}>
                <DialogTitle>
                    Tem certeza que deseja remover o usuário {user?.name}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteUserDialog(false)}
                        className={classes.deleteUserDialogCancelButton}
                        disabled={loadingDeleteUser}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteUser} color="primary" autoFocus
                        disabled={loadingDeleteUser}
                        endIcon={loadingDeleteUser && (
                            <CircularProgress size={15} color="inherit" />
                        )}
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog >

            <Dialog aria-labelledby="simple-dialog-title" open={openedRequestPasswordRecoveryDialog}>
                <DialogTitle>
                    Tem certeza que deseja solicitar a recuperação de senha para o usuário {user?.name}?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedRequestPasswordRecoveryDialog(false)}
                        className={classes.RequestPasswordRecoveryDialogCancelButton}
                        disabled={loadingRequestPasswordRecovery}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleRequestPasswordRecovery} color="primary" autoFocus
                        disabled={loadingRequestPasswordRecovery}
                        endIcon={loadingRequestPasswordRecovery && (
                            <CircularProgress size={15} color="inherit" />
                        )}
                    >
                        Solicitar
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default Users
